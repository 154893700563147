import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setConfig } from "../state/configSlice";
import { getAuthenticatedUser, logout} from '../state/thunks';
import { postApi } from '../state/thunkPostApi';
import { NavLink } from '../components/nav/NavLink';
import { ProfileImg } from '../components/profile/ProfileImg';
import { NavLogo } from '../components/nav/NavLogo';
import { selectUser } from '../state/userSlice';

export const Header = () => {
  const dispatch = useDispatch();
  const setLogin = () => {dispatch(setConfig({displayLoginSignup: 'login'}))};
  const setLogout = () => {dispatch(logout())};
  const authUser = useSelector((state) => state.config.authUser)
  const user = useSelector((state) => selectUser(state));

  const [badgeCount, setBadgeCount] = useState(0)


  //check for auth user on first mount only and put into state; no dependencies on purpose
  useEffect(() => {    
    dispatch(getAuthenticatedUser())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //track changes to authUser and obtain profile on change
  useEffect(() => {
    if(authUser && authUser !== 'nonauth') {
      dispatch(postApi({path:'/getProfile', obj:{profileId:authUser, scope: 'n'}}))
    }
   }, [dispatch, authUser]);

  //badge
  useEffect(() => { 
    let counter = 0;   
    if(user?.authUser && user?.authUser !== 'nonauth'){
      ['cohort','education','gender','income', 'politics'].forEach((i)=>{
        if(user?.[i]?.length === 2 && user?.[i].endsWith('U')){counter +=1}
      })
      setBadgeCount(counter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  

  return (
    <div className="Header" role="banner">
      <NavLogo/>
      {(authUser && authUser !== 'nonauth') && 
        <div role="navigation" className="navGroup">
          <ProfileImg badgeCount={badgeCount}/>
          <NavLink text="Logout" onClick={setLogout}/>
        </div>
      }
      {(!authUser || authUser === 'nonauth') && 
        <div role="navigation" className="navGroup">
          <NavLink text="Login" onClick={setLogin}/>
        </div>
      }
    </div>
  );
}
  