import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const adsAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => i.SK.substring(2),
})
  
const adsSlice = createSlice({
    name: 'ads',
    initialState: adsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
          .addCase('config/resetAudience', (state) => {
            adsAdapter.removeAll(state)
          })
          .addCase(logout.pending, (state) => {
            adsAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.ads?.updated?.length >0){
                adsAdapter.upsertMany(state, action.payload.ads.updated)
            }
            if(action?.payload?.audiences?.deleted?.length >0){
              adsAdapter.removeMany(state, action.payload.ads.deleted)
            }
          })
    }
})

export default adsSlice.reducer