import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const lastCommentsAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => i.PK,
})
  
const lastCommentsSlice = createSlice({
    name: 'lastComments',
    initialState: lastCommentsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
      //state in extraReducers is just this slice
        builder
          .addCase('activities/deleteActivity', (state, action) => {
            lastCommentsAdapter.removeOne(state, action.payload)
          })
          .addCase('config/resetStream', (state) => {
            lastCommentsAdapter.removeAll(state)
          })
          .addCase('config/resetAudience', (state) => {
            lastCommentsAdapter.removeAll(state)
          })
          .addCase(login.fulfilled, (state, action) => {
            if(action?.payload !== 'NEW_PASSWORD_REQUIRED' && action?.payload?.activeView !== 'activity' && action.payload?.activeView !== 'podcast'){
                lastCommentsAdapter.removeAll(state)
            }
          })
          .addCase(logout.pending, (state) => {
            lastCommentsAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.lastComments?.updated?.length >0){
                lastCommentsAdapter.upsertMany(state, action.payload.lastComments.updated)
            }
            if(action?.payload?.lastComments?.deleted?.length >0){
                lastCommentsAdapter.removeMany(state, action.payload.lastComments.deleted)
            }
          })
    }
})

export default lastCommentsSlice.reducer