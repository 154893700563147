import { createSlice } from '@reduxjs/toolkit';
import { getAuthenticatedUser } from "./thunks";
import { postApi } from './thunkPostApi';
import { hasIdenticalParams } from './stateLib';

const initialState = {}
  
export const debounceSlice = createSlice({
  name: 'debounce',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => { 
    builder
      .addCase(getAuthenticatedUser.pending, (state) => {
        if(!state?.['getAuthenticatedUser']){
          state.getAuthenticatedUser = []
        }
      })
      .addCase(getAuthenticatedUser.fulfilled, (state) => {
        delete state['getAuthenticatedUser']
      })
      .addCase(getAuthenticatedUser.rejected, (state) => {
        delete state['getAuthenticatedUser']
      })
      .addCase(postApi.pending, (state, action) => {
        const { meta } = action;
        const path = meta?.arg?.path || null;
        const obj = meta?.arg?.obj || null;
        const shortPath = (path) ? path.substring(1) : null;
        if(path === '/updateAction' || path === '/refreshEntities' || path === '/selectAds'){
          //nothing to record (not debouncing actions; debouncing refresh separately)
          return
        } else {
          if(shortPath && (!state?.[shortPath] || !hasIdenticalParams(state?.[shortPath], obj))){
            if(!state?.[shortPath]){state[shortPath] = []}
            state[shortPath] = [...state[shortPath], {...obj}]
          }
          return
        } 
      })
      .addCase(postApi.fulfilled, (state, action) => {
        const { meta, requestId } = action;
        const path = meta?.arg?.path || null;
        const shortPath = (path) ? path.substring(1): null;
        if(shortPath && state?.[shortPath]){
          const idx = state[shortPath].findIndex((i) => i.requestId === requestId)
          if(idx !== -1) {state[shortPath].splice(idx,1)};
        };
      })
      .addCase(postApi.rejected, (state, action) => {
        const { meta, requestId } = action;
        const path = meta?.arg?.path || null;
        const shortPath = (path) ? path.substring(1): null;
        if(shortPath && state?.[shortPath]){
          const idx = state[shortPath].findIndex((i) => i.requestId === requestId)
          if(idx !== -1) {state[shortPath].splice(idx,1)};
        };
      })
  },
})

export default debounceSlice.reducer 
