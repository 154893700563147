export const createKey = async (key) => {
    let encoded = new TextEncoder().encode(key)
    const result = await window.crypto.subtle.importKey(
        'raw',
        encoded,
        {
            name: 'AES-CTR',
        },
        true,
        ['decrypt', 'encrypt'],
    );
    return result
}