import { NavLink } from '../components/nav/NavLink';
import { changePage } from '../state/configSlice';
import { useDispatch } from 'react-redux';

export const Footer = () => {
  const dispatch = useDispatch();
  return (
    <div className="Footer" id="Footer" role="contentinfo">
      <div className='footerRow'>
        <div>
          <NavLink text="Podcasters" onClick={()=> dispatch(changePage({activeView:'podcasters'}))}/> 
        </div>
        <div>
          <NavLink text="Terms" onClick={()=> dispatch(changePage({activeView:'terms'}))}/>
          <NavLink text="Privacy" onClick={()=> dispatch(changePage({activeView:'privacy'}))}/>
          <NavLink text="Cookies" onClick={()=> dispatch(changePage({activeView:'cookie'}))}/>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        © 2023 Poditude, Inc.
      </div>
    </div>
  );
  }
