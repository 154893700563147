import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';

export const IconButton = ({icon, onClick, spinner=false, tooltip, color='ltBlue', css="activityButton", tipLocation='top'}) => {
  const buttonClasses = 'shadow-none ' + css + ' ' + color
  //onClick
  const [ refreshSpinner, setRefreshSpinner ] = useState(false);
  const handleClick = async(e) => {
    e.preventDefault();
    if(spinner){setRefreshSpinner(true)};
    await Promise.resolve(onClick())
    .then (()=> {
      if(spinner){setRefreshSpinner(false)};
    })
    .catch(()=> {
      if(spinner){setRefreshSpinner(false)};
    })
  }

  //icon 
  let visibleIcon = (refreshSpinner) ? <Spinner size="sm" animation="border" variant="light" /> : icon

  //tooltip
  const [show, setShow] = useState(false)
  const handleToggle = (next) => {
    if(next===true){
      setShow(true);
      setTimeout(() => {
        setShow(false)
      }, "2000");
    };
  };

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={tipLocation}
      show={show}
      delay={{ delay: 250}}
      onToggle={handleToggle}
      overlay={<Tooltip className ='tooltip' id="button-tooltip">{tooltip}</Tooltip>}
    >
      <Button 
        type="button" 
        aria-label={tooltip}
        className={buttonClasses}
        onClick={(e)=>handleClick(e)}
      >{visibleIcon}</Button>
    </OverlayTrigger>
  );
}