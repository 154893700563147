import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';
 
const advertisersAdapter = createEntityAdapter({
    // id
    selectId: (i) => i.SK.substring(2),
    // sort alpha by title
    sortComparer: (a, b) => a.advertiserName.localeCompare(b.advertiserName),
})
  
const advertisersSlice = createSlice({
    name: 'advertisers',
    initialState: advertisersAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
          .addCase('config/resetAudience', (state) => {
            advertisersAdapter.removeAll(state)
          })
          .addCase(logout.pending, (state) => {
            advertisersAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.advertisers?.updated?.length >0){
                advertisersAdapter.upsertMany(state, action.payload.advertisers.updated)
            }
            if(action?.payload?.audiences?.deleted?.length >0){
              advertisersAdapter.removeMany(state, action.payload.advertisers.deleted)
            }
          })

    }
})

export default advertisersSlice.reducer