import { configureStore } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import actionsReducer from './actionsSlice';
import activitiesReducer from './activitiesSlice';
import adsReducer from './adsSlice';
import advertisersReducer from './advertisersSlice';
import audiencesReducer from './audiencesSlice';
import audienceFormReducer from './audienceFormSlice';
import chartsReducer from './chartsSlice';
import campaignsReducer from './campaignsSlice';
import commentsReducer from './commentsSlice';
import debounceSlice from './debounceSlice';
import lastCommentsReducer from './lastCommentsSlice';
import notificationsReducer from './notificationsSlice';
import permissionsReducer from './permissionsSlice';
import profilesReducer from './profilesSlice';
import userReducer from './userSlice';
import refreshReducer from './refreshSlice';
import searchSlice from './searchSlice';
import streamSlice from './streamSlice';

export const store = configureStore({
  reducer: {
    config: configReducer,
    actions: actionsReducer, 
    activities: activitiesReducer,
    ads: adsReducer,
    advertisers: advertisersReducer,
    audienceForm: audienceFormReducer,
    audiences: audiencesReducer, 
    campaigns: campaignsReducer,
    charts: chartsReducer,
    comments: commentsReducer, 
    debounce: debounceSlice,
    lastComments: lastCommentsReducer,
    notifications: notificationsReducer,
    permissions: permissionsReducer,
    profiles: profilesReducer,
    refresh: refreshReducer,
    search: searchSlice,
    stream: streamSlice,
    user: userReducer
  },
  devTools: (process.env.REACT_APP_STAGE==='dev') ? true : false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})


