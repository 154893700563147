import { arrayBufferToHex } from "./arrayBufferToHex";
import { createHash } from "./createHash";
import { createKey } from "./createKey";
 
export const encryptBody = async (body) => {
    if(process.env.REACT_APP_STAGE === process.env.REACT_APP_ENCRYPT){
        try{
            //derive key and iv from random integers
            const seed1rnd = Math.floor(Math.random()*100);
            const seed2rnd = Math.floor(Math.random()*100);
            const hashedEncryptionKey = (await createHash(process.env.REACT_APP_SEED_1.substring(seed1rnd, 32))).substring(0,32);
            const hashedInitializationVector = (await createHash(process.env.REACT_APP_SEED_2.substring(seed2rnd, 16))).substring(0,16);
            
            //create crypto key
            const key = await createKey(hashedEncryptionKey)
            
            //encode plain text body as a Uint8Array
            let bufferedText = new TextEncoder().encode(JSON.stringify(body))
            
            //convert iv to Uint8Array
            let encodedIv = new TextEncoder().encode(hashedInitializationVector)
            
            //use js native subtleCrypto to encrypt
            const encryptedArrayBuffer = await window.crypto.subtle.encrypt(
                { name: "AES-CTR", counter: encodedIv, length: 64 },
                key,
                bufferedText,
            );
            
            //convert EAB to hex string
            const typedArray = new Uint8Array(encryptedArrayBuffer)
            
            const result = arrayBufferToHex(typedArray) 
            if (result) {
                return seed1rnd + '::' + seed2rnd + '::' + result
                // return {a: seed1rnd, b: seed2rnd, body: result}
            } else {
                return body
            }
        } catch(e){
            
        }
    } else {
        return body
    }
}