import { createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const commentsAdapter = createEntityAdapter({
    // id=PK#SK
    selectId: (i) => `${i.PK.substring(2)}#${i.SK}`,
    // sort alpha by creation date
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})
  
const commentsSlice = createSlice({
    name: 'comments',
    initialState: commentsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
        //state in extraReducers is just this slice
          .addCase('activities/deleteActivity', (state, action) => {
            const ids = Object.values(state.entities).filter(v => v.PK.substring(2) === action.payload).map(i => `${i.PK.substring(2)}#${i.SK}`)
            commentsAdapter.removeMany(state, ids)
          })
          .addCase('config/resetStream', (state) => {
            commentsAdapter.removeAll(state)
          })
          .addCase('config/resetAudience', (state) => {
            commentsAdapter.removeAll(state)
          })
          .addCase(login.fulfilled, (state, action) => {
            if(action?.payload !== 'NEW_PASSWORD_REQUIRED' && action?.payload?.activeView !== 'activity' && action?.payload?.activeView !== 'podcast'){
                commentsAdapter.removeAll(state)
            }
          })
          .addCase(logout.pending, (state) => {
            commentsAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.comments !== 'empty' && action?.payload?.comments?.updated?.length){
                commentsAdapter.upsertMany(state, action.payload.comments.updated)
            }
            if(action?.payload?.comments?.deleted?.length >0){
                commentsAdapter.removeMany(state, action.payload.comments.deleted)
            }
          })
    }
})
  
// selector must be given entire state; knows how to find slice
export const selectCommentIdsByActivityIdAB = createSelector(
    [(state) => state.comments.entities, (state, activityId) => activityId],
    (comments, activityId) => {
        return Object.entries(comments).filter(([k,v]) => k.startsWith(activityId)).map(([k,v]) => {return k})
    }
)

export default commentsSlice.reducer