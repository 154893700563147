import { createSlice } from '@reduxjs/toolkit';
import { confirmAdminCreateUser, getAuthenticatedUser, logout, login} from './thunks';
import { postApi } from "./thunkPostApi";

const initialState = {
  activeFeedname: 'user',
  activeFeedactor: 'nonauth',
  activeView: null, 
  authUser: 'nonauth',
  displayHeadline: true,
  displayChannelSelector: false,
  displayLoginSignup: null,
  displayModal: false,
  displayNotifications: false,
  displayProfile: false, 
  displaySkeleton: true,
  href: null,
  modalText: '',
  pathname: '',
  selectedChannel: null,
  streamNext: null,
}
  
export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    changeDisplayLoginSignup: (state, action) => {
     
      const { payload } = action;
      //expects payload as obj with str: value
      const { str } = payload;
      const activeView = state.activeView;
      if(activeView==='activity' || activeView==='podcast'){
        return {...state, ...{displayLoginSignup: str}};
      } else {
        window.history.replaceState({}, '', '/');
        return {...state, ...{displayLoginSignup: str, pathname:'', href: null}};  
      }
    },
    setConfig: (state, action) => {
      const { payload } = action;
      //expects payload as obj with variableName: value pairs
      return {...state, ...payload}
    },
    toggleItem: (state, action) => {
      //expects payload as name of {item: item to toggle}
      const {item} = action.payload
      state[item] = !state[item]
    },
    resetStream: (state, action) => {
      window.scroll(0,0);
      const { payload } = action;
      return {...state, ...{streamNext: null, displaySkeleton: true}, ...payload};
    },
    resetAudience: (state, action) => {
      window.scroll(0,0);
      const { payload } = action;
      const {activeFeedactor, activeFeedname=null, activeView=null} = payload
      return {...state, ...{displaySkeleton: false, activeFeedactor, streamNext: null,  ...activeFeedname && {activeFeedname}, ...activeView && {activeView}}};
    },
    changePage: (state, action) => {
      const { payload } = action;
      const newUrl = `/${payload.activeView}`
      window.scroll(0,0);
      window.history.replaceState({}, '', newUrl);
      //expects payload as obj with variableName: value pairs
      return {...state, ...payload}
    },
    monitorURL: (state) => {
      const newHref = window.location.href
      const url = new URL(newHref);
      let pathParams = null;
      if(url?.pathname.includes('/')){
        const pathSplit = url.pathname.split('/');
        //put split[1] in view and split[2] in activeFeedactor
        if((pathSplit?.[1]==='activity' || pathSplit?.[1]==='podcast' ) && pathSplit?.[2]){
          const shortId = (pathSplit?.[2].includes('#')) ? pathSplit[2].split('#')[0] : pathSplit[2];
          pathParams = {
            activeView: pathSplit[1],
            activeFeedactor: shortId
          }
        } else if(pathSplit?.[1]==='podcasters' || pathSplit?.[1]==='terms' || pathSplit?.[1]==='privacy' || pathSplit?.[1]==='cookie'){
          pathParams = {
            activeView: pathSplit[1],
          }
        } else if(['login', 'signup', 'reset', 'verify', 'claim', 'changeEmail', 'verifyEmail'].includes(pathSplit?.[1])){
          pathParams = {
            displayLoginSignup: pathSplit[1],
            activeView: 'stream'
          }
        } else {
          pathParams = {
            activeView: 'stream',
          }
        }
      }
      return {...state, ...{href: newHref, hostname: url.hostname, pathname:url.pathname, ...pathParams && {...pathParams}}}
    },
    //add additional customized actions here
  },
  extraReducers: (builder) => { 
    builder
      .addCase(confirmAdminCreateUser.fulfilled, (state, action) => {
        if(!action.payload.msg){state.authUser = action.payload}
      })
      .addCase(getAuthenticatedUser.rejected, (state) => {
        return {...state, ...{ activeFeedname: 'user', activeFeedactor: 'nonauth', authUser: 'nonauth', authUserChecked:true}}
      })
      .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
        const { payload } = action;
        return {...state, ...{ activeFeedname: 'user', ...(!state.activeFeedactor || state.activeFeedactor==='nonauth') && {activeFeedactor: payload}, authUser: payload, authUserChecked:true}}
      })
      .addCase(login.fulfilled, (state, action) => {
        if(action?.payload !== 'NEW_PASSWORD_REQUIRED'){
          if(action?.payload?.activeView === 'activity' || action?.payload?.activeView === 'podcast'){
            //login from single activity or single audience
            return {...state, ...{ authUser: action.payload.user, authUserChecked: true, displayLoginSignup:false}}
          } else {
            //typical login
            return {...state, ...{activeFeedname: 'user', activeFeedactor: action.payload.user, authUser: action.payload.user, displaySkeleton: true, authUserChecked: true}}
          }
          
        }
      })
      .addCase(logout.pending, (state) => {
        window.history.replaceState({}, '', '/');
        return {...initialState, ...{windowSize: state.windowSize, authUserChecked:true, authUser: 'nonauth', activeView: 'stream'}}
      }) 
      .addCase(logout.fulfilled, (state) => {
        window.history.replaceState({}, '', '/');
        return {...state, ...{authUserChecked:false, displayLoginSignup: false}}
      })
      .addCase(postApi.pending, (state, action) => {
        const { meta } = action;
        if (meta?.arg?.path === '/getAudience' ){
          window.scroll(0, 0)
          return {...state, ...{ displaySkeleton: true}};
        }
        if (meta?.arg?.path === '/getStream' ){
          window.scrollBy(0,-50)
          return {...state};
        }
        if (meta?.arg?.path === '/getSearch' ){
          window.scroll(0, 0)
          return {...state, ...{activeView: 'streamSearch', displaySkeleton: true}};
        }
      }) 
      .addCase(postApi.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const updated = (payload?.config?.updated) ? payload.config.updated : {};
        if (meta?.arg?.path === '/updateAudience' || meta?.arg?.path === '/addEntitiesFromRSS'){
          const audience = (payload?.audiences?.updated) ? payload.audiences.updated[0] : null;
          const audienceId = (audience) ? audience.PK: null;
          return {...state, ...updated, ...audienceId && {activeFeedactor:audienceId}};
        }
        if (meta?.arg?.path === '/getStream' || meta?.arg?.path === '/getAudience' || meta?.arg?.path === '/getSearch' ){
          return {...state, ...updated, displaySkeleton: false};
        }
      })
      .addCase(postApi.rejected, (state, action) => {
        const { meta } = action;
        if (state?.displaySkeleton && meta?.arg?.path === '/getStream'){
          state.displaySkeleton = false
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { changeDisplayLoginSignup, changePage, monitorURL, resetAudience, resetStream, setConfig, toggleItem } = configSlice.actions

export default configSlice.reducer 
