import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const searchAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => i.id,
    // sort alpha by creation date
    sortComparer: (a, b) => a.title.localeCompare(b.title),
})
  
const searchSlice = createSlice({
    name: 'search',
    initialState: searchAdapter.getInitialState(),
    reducers: {
      clearSearch: (state) => {
        searchAdapter.removeAll(state)
      },
    },
    extraReducers: (builder) => {
      //state in extraReducers is just this slice
        builder
        .addCase('config/resetStream', (state) => {
          searchAdapter.removeAll(state)
        })
        .addCase('config/resetAudience', (state) => {
          searchAdapter.removeAll(state)
        })
        .addCase(login.fulfilled, (state, action) => {
          if(action?.payload !== 'NEW_PASSWORD_REQUIRED'){
              searchAdapter.removeAll(state)
          }
        })
        .addCase(logout.pending, (state) => {
          searchAdapter.removeAll(state)
        })
        .addCase(postApi.fulfilled, (state, action) => {
          if(action?.payload?.search?.updated?.length >0){
              searchAdapter.removeAll(state)
              searchAdapter.setAll(state, action.payload.search.updated)
          }
        })
    }
})
  
export const { clearSearch } = searchSlice.actions
export default searchSlice.reducer