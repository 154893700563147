import { createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const notificationsAdapter = createEntityAdapter({
    // id=PK#SK
    selectId: (i) => `${i.PK}#${i.SK.substring(2)}`,
    // sort alpha by PK#SK
    sortComparer: (a, b) => {
        if(a?.PK === a?.audienceId && b?.PK === b?.audienceId){
            a.targetName.localeCompare(b.targetName)
        } else if (a?.PK === a?.targetId && b?.PK === b?.targetId){
            a.audienceTitle.localeCompare(b.audienceTitle)
        } else if (a?.PK === a?.audienceId && b?.PK === b?.targetId){
            return 1
        } else {
            return -1
        }
    },
})
  
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: notificationsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
            .addCase(logout.pending, (state) => {
            notificationsAdapter.removeAll(state)
            })
            .addCase(postApi.fulfilled, (state, action) => {
                if(action?.payload?.notifications?.updated?.length >0){
                    notificationsAdapter.upsertMany(state, action.payload.notifications.updated)
                }
                if(action?.payload?.notifications?.deleted?.length >0){
                    notificationsAdapter.removeMany(state, action.payload.notifications.deleted)
                }
            })
          
    }
})
  
// selector must be given entire state; knows how to find slice
export const selectNotificationIdsByPK = createSelector(
    [(state) => state.notifications.entities, (state, PK) => PK],
    (notifications, PK) => {
        return Object.entries(notifications).filter(([k,v]) => k.startsWith(PK)).map(([k,v]) => {return k})
    }
)


export default notificationsSlice.reducer