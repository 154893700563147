import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { store } from './state/store';
import { Provider } from 'react-redux';
import 'rc-slider/assets/index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById("root"));
//prevent logging to console
if(process.env.REACT_APP_STAGE === 'prod'){window.console = {
  log: ()=>{}, info: ()=>{}, error: ()=>{}, warn: ()=>{}
}}

root.render(
  // <React.StrictMode> 
    <Router>
        <Provider store={store}>
            <App/> 
        </Provider>  
    </Router>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


 