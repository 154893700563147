import { useSyncExternalStore } from 'react';

//check windowSize using useSyncExternalStore
const getSnapshot = ()=> {
  return window.innerWidth; 
}

const handleResize = () => { 
  
}

const subscribe = () => {
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('load', handleResize);
        window.removeEventListener('resize', handleResize);
    };
}

export const useWindowSize = () =>{
  const windowSize = useSyncExternalStore(subscribe, getSnapshot);
  return windowSize;
}