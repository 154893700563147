import { createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';

const chartsAdapter = createEntityAdapter({ 
    // id=PK#SK
    selectId: (i) => i.id,
})
  
const chartsSlice = createSlice({
    name: 'charts',
    initialState: chartsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
            .addCase('activities/deleteActivity', (state, action) => {
                chartsAdapter.removeOne(state, action.payload)
            })
            .addCase(logout.pending, () => {
                chartsAdapter.getInitialState()
            })
            .addCase(postApi.fulfilled, (state, action) => {
                if(action?.payload?.charts?.updated?.length >0){
                    chartsAdapter.upsertMany(state, action.payload.charts.updated)
                }
                if(action?.payload?.charts?.deleted?.length >0){
                    chartsAdapter.removeMany(state, action.payload.charts.deleted)
                }
            })
            .addCase('config/resetAudience', (state, action) => {
                const ids = Object.keys(state.entities).filter(i => !i.startsWith(action.payload.authUser))
                chartsAdapter.removeMany(state, ids)
            })
    }
})
  
// selector must be given entire state; knows how to find slice
export const selectChartByIdFromTo = createSelector(
    [(state) => state.charts.entities, (state, id) => id, (state, id, from) => from, (state, id, from, to) => to],
    (charts, id, from, to) => {
        const X = (charts[`${id}`]) ? Object.entries(charts[`${id}`]).filter(([k,v]) => ((k >= from && k <= to) || (k >= 'xb' && k <= 'xcZ')))  : null;
        const A = (charts[`${id}#A`]) ? Object.entries(charts[`${id}#A`]).filter(([k,v]) => ((k >= from && k <= to) || (k >= 'xb' && k <= 'xcZ'))) : null;
        const B = (charts[`${id}#B`]) ? Object.entries(charts[`${id}#B`]).filter(([k,v]) => ((k >= from && k <= to) || (k >= 'xb' && k <= 'xcZ'))) : null;
        let response = {};
        if (X && X.length>0){response[`${id}`] = Object.fromEntries(X)};
        if (A && A.length>0){response[`${id}#A`] = Object.fromEntries(A)};
        if (B && B.length>0){response[`${id}#B`] = Object.fromEntries(B)};
        if(Object.keys(response).length>0){
            return response
        } else {
            return null
        }
    }
)


export default chartsSlice.reducer