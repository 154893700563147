import { useDispatch, useSelector } from "react-redux";
import { setConfig } from "../../state/configSlice";
import { selectUser } from "../../state/userSlice";

export const ProfileImg = ({badgeCount=null}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state)) || {}
  const displayProfile = useSelector((state) => state.config.displayProfile);
  const useInitials = (user.avInitials) ? user.avInitials : (user.fName && user.lName) ? user.fName.charAt(0) + user.lName.charAt(0) : (user.fName) ? user.fName.charAt(0) : (user.email) ? user.email.charAt(0) : null;
  const toggleDisplayProfile = () => {
    if(!displayProfile){window.scroll(0,0)}
    dispatch(setConfig({displayProfile: !displayProfile}))
  }
  
  
  const options = () => {
    if(user.avTime && user.avTime !== 'false' && user.PK){
      return(
        <button 
          tabIndex="0"
          aria-label="User Profile"
          className="profileImg"
          style={{backgroundColor: user.avColor, borderColor: user.avColor}}
          onClick={toggleDisplayProfile}
        >
          <img src={`${process.env.REACT_APP_IMAGE_URL}${user.PK}.png?${user.avTime}`} className="img-fluid profileImg" onClick={toggleDisplayProfile} alt={useInitials.toUpperCase()}/>
          {badgeCount>0 && <div className="badge" role="status">{badgeCount}</div>}
        </button>
      )
    } else {
      const initials = (useInitials) ? useInitials.toUpperCase() : '';
      return(
        <button 
          className="profileImg"
          style={{backgroundColor: user.avColor, borderColor: user.avColor}}
          onClick={toggleDisplayProfile}
        >
          {initials}
          {badgeCount>0 && <div className="badge" role="status">{badgeCount}</div>}
        </button>
      )
    }
  }
  
  return (
    <>
      {options()}
    </>
  );
}