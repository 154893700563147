import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi';
 
const activitiesAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => i.PK,
    // sort alpha by creation date
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})
  
const activitiesSlice = createSlice({
    name: 'activities',
    initialState: activitiesAdapter.getInitialState(),
    reducers: {
      deleteActivity: (state, action) => {
        activitiesAdapter.removeOne(state, action.payload)
      },
    },
    extraReducers: (builder) => {
      //state in extraReducers is just this slice
        builder
        .addCase('config/resetStream', (state) => {
          activitiesAdapter.removeAll(state)
        })
        .addCase('config/resetAudience', (state) => {
          activitiesAdapter.removeAll(state)
        })
        .addCase(login.fulfilled, (state, action) => {
          if(action?.payload !== 'NEW_PASSWORD_REQUIRED' && action?.payload?.activeView !== 'activity' && action.payload?.activeView !== 'podcast'){
              activitiesAdapter.removeAll(state)
          }
        })
        .addCase(logout.pending, (state) => {
          activitiesAdapter.removeAll(state)
        })
        .addCase(postApi.fulfilled, (state, action) => {
          if(action?.payload?.activities?.updated?.length >0){
              activitiesAdapter.upsertMany(state, action.payload.activities.updated)
          }
          if(action?.payload?.activities?.deleted?.length >0){
              activitiesAdapter.removeMany(state, action.payload.activities.deleted)
          }
        })
    }
})
  
export const { deleteActivity } = activitiesSlice.actions
export default activitiesSlice.reducer