import { createSlice } from '@reduxjs/toolkit';
import { postApi } from "./thunkPostApi";
 
const initialState = {
    activeNetwork: null,
    displayAudienceAdmin: false,
    displayAudienceCreate: false,
    displayAvatarMaker: false,
    errors: [],
    focusActivity: null,
    focusChart: 'membersDemo',
    isSwitchOn: false,
    loading: false,
    msg: '',
    postType: 'blank',
    selectedAudience : null,
    selectedNetwork : null,
    //form fields
    fields: {},
}

export const setDefaultFormData = (payload) => {
    if(payload?.postType){
        if(payload?.postType==='question'){
            return{
              verb: 'asked',
              qTextA: 'How Likely Are You To Ask a Great Question on Poditude?',
              r1TextA: 'Highly Unlikely',
              r2TextA: 'Unlikely',
              r3TextA: 'Neutral',
              r4TextA: 'Likely',
              r5TextA: 'Highly Likely',
              tooltipA: 'Poditude is a great place to ask questions and get feedback',
              qTextB: 'How Likely Are You To Ask a Another Great Question on Poditude?',
              r1TextB: 'Highly Unlikely',
              r2TextB: 'Unlikely',
              r3TextB: 'Neutral',
              r4TextB: 'Likely',
              r5TextB: 'Highly Likely',
              tooltipB: 'Poditude is a great place to ask questions and get feedback',
            } 
        } else if (payload?.postType==='link'){
            return{
                verb: 'linked',
                linkURL: 'https://poditude.com',
                linkContentType: "text/html; charset=utf-8",
                linkIntro: 'The link below is our special treat to our podcast listeners',
                linkSite: "Poditude.com",
                linkTitle: "Edit details for your image on the right.",
                linkDescription: "This is a sample image.",
                linkImage: `${window.location.origin}/horizontal_2500_1500.png`,
            }
        } else if (payload?.postType==='text'){
            return{
                verb: 'wrote', 
                introA: 'Big news from us to you',
                shareTypeA: 'an Update',
                fontSizeA: 1,
                fontColorA:'#353935',
                bkndColorA:'#fffffa',
                txtA: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
                linkURLA: 'https://poditude.com',
                introB: 'Big news from us to you',
                shareTypeB: 'an Update',
                fontSizeB: 1,
                fontColorB:'#353935',
                bkndColorB:'#fffffa',
                txtB: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
                linkURLB: 'https://poditude.com',
            }
        } else if (payload?.postType==='image') {
          return{
              verb: 'shared',
              introA: 'Big news from us to you',
              linkURLA: 'https://poditude.com',
              imageA: `${window.location.origin}/horizontal_2500_1500.png`,
              introB: 'Here is a clickable image we shared',
              linkURLB: 'https://poditude.com',
              imageB: `${window.location.origin}/horizontal_2500_1500.png`,
          }
      } else if (payload?.postType==='video') {
            return{
                verb: 'linked',
                linkIntro: 'Big video news from us to you',
                linkContentType: 'video'
            }
        } else if (payload?.postType==='network') {
          return{
              title: 'Our Latest Podcast Network',
              description: 'The latest and greatest collection of podcasts we want to manage together.',
              link: 'https://www.poditude.com',
              ownerName: 'Podcast Manager',
              email: 'podowners@poditude.com',
              imageURL: `${window.location.origin}/logo_512_512.png`,
              podcasts: []
          }
      } else {
          return {}
        }
    }
}
  
export const audienceFormSlice = createSlice({
  name: 'audienceForm',
  initialState,
  reducers: {
    setAudienceForm: (state, action) => {
      const { payload } = action;
      const fields = (payload.postType) ? setDefaultFormData(payload) : (payload.fields) ? {...state.fields, ...payload.fields} : null;
      delete payload.fields
      return {...state, ...payload, ...fields && {fields}}
    },
    setAudienceFormField: (state, action) => {
      const { payload } = action;
      const newFields = {...state.fields, ...payload};
      state.fields = newFields;
      state.errors = '';
      state.msg = ''
      return state
    },
    resetAudienceForm: (state, action) => {
      const { payload } = action;
      return { state, fields: setDefaultFormData(payload)}
    }
    
    //add additional customized actions here
  },
  extraReducers: (builder) => { 
    builder
      .addCase(postApi.fulfilled, (state, action) => {
        const { meta, payload } = action;
        if (meta?.arg?.path === '/updateAudience' || meta?.arg?.path === '/addEntitiesFromRSS'){
          const audience = (payload.audiences?.updated) ? payload.audiences.updated[0] : null;
          return {...state, ...audience && {selectedAudience:audience.title}};
        }
      })
      .addCase('config/resetAudience', (state, action) => {
        const { payload } = action;
        const {displayAudienceCreate, selectedAudience=null} = payload;
        return {...state, ...{ postType: 'blank', displayAudienceCreate, focusActivity: null, fields: {}, focusChart: 'membersDemo', ...selectedAudience && {selectedAudience}}};
      })
  },
})

// Action creators are generated for each case reducer function
export const { resetAudienceForm, setAudienceForm, setAudienceFormField } = audienceFormSlice.actions

export default audienceFormSlice.reducer 