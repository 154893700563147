import { createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const permissionsAdapter = createEntityAdapter({
    // id=PK#SK
    selectId: (i) => `${i.PK}#${i.SK.substring(2)}`,
    // sort alpha by PK#SK
    sortComparer: (a, b) => {
        if(a?.PK === a?.profileId && b?.PK === b?.profileId){
            a.audienceTitle.localeCompare(b.audienceTitle)
        } else if (a?.PK === a?.audienceId && b?.PK === b?.audienceId){
            a.profileName.localeCompare(b.profileName)
        } else if (a?.PK === a?.profileId && b?.PK === b?.audienceId){
            return 1
        } else {
            return -1
        }
    },
})
  
const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: permissionsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
            .addCase('config/resetAudience', (state, action) => {
                const ids = Object.keys(state.entities).filter(i => !i.startsWith(action.payload.authUser))
                permissionsAdapter.removeMany(state, ids)
            })
            .addCase(logout.pending, (state) => {
                permissionsAdapter.removeAll(state)
            })
            .addCase(postApi.fulfilled, (state, action) => {
                if(action?.payload?.permissions?.updated?.length >0){
                    permissionsAdapter.upsertMany(state, action.payload.permissions.updated)
                }
                if(action?.payload?.permissions?.deleted?.length >0){
                    permissionsAdapter.removeMany(state, action.payload.permissions.deleted)
                }
            })
          
    }
})
  
// selector must be given entire state; knows how to find slice
export const selectPermissionsByPK = createSelector(
    [(state) => state.permissions.entities, (state, PK) => PK],
    (permissions, PK) => {
        return Object.entries(permissions).filter(([k,v]) => k.startsWith(PK)).map(([k,v]) => {return v})
    }
)

export const selectPermissionIdsByPK = createSelector(
    [(state) => state.permissions.entities, (state, PK) => PK],
    (permissions, PK) => {
        return Object.entries(permissions).filter(([k,v]) => k.startsWith(PK)).map(([k,v]) => {return k})
    }
)

export default permissionsSlice.reducer