import { createHash } from "./createHash";
import { createKey } from "./createKey";
import { createBufferFromHexString } from "./createBufferFromHexString";
 
export const decryptResponse = async (response) => {
    try{
        if(typeof response === 'string'){
            //derive key and iv from random integers
            const splits = response.split('::');
            const seed1rnd = Number(splits[0]);
            const seed2rnd = Number(splits[1]);
            const hashedEncryptionKey = (await createHash(process.env.REACT_APP_SEED_1.substring(seed1rnd, 32))).substring(0,32);
            const hashedInitializationVector = (await createHash(process.env.REACT_APP_SEED_2.substring(seed2rnd, 16))).substring(0,16);
            
            //create crypto key
            const key = await createKey(hashedEncryptionKey);
           
            //encode plain text body as a Uint8Array
            let bufferedText = createBufferFromHexString(splits[2]);
            
            //convert iv to Uint8Array
            let encodedIv = new TextEncoder().encode(hashedInitializationVector)
            
            //use js native subtleCrypto to decrypt
            const decryptedArrayBuffer = await window.crypto.subtle.decrypt(
                { name: "AES-CTR", counter: encodedIv, length: 64 },
                key,
                bufferedText,
            );
            
            const result = new TextDecoder().decode(decryptedArrayBuffer) 
            if (result) {
                return JSON.parse(result)
            } else {
                return {}
            }    
        } else {
            return response
        }
    }catch(e){
        return {}
    }
}