import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const profilesAdapter = createEntityAdapter({
    // PK
    selectId: (i) => i.PK,
    // sort alpha by lName if it exists; by email otherwise
    sortComparer: (a, b) => {
        if (a?.lName && b?.lName && a?.lName < b?.lName) {
            return -1;
        } else if (a?.lName && b?.lName && a?.lName > b?.lName) {
            return 1;
        } else if (a?.lName && b?.lName && a?.lName === b?.lName) {
            return 0;
        } else if (a?.email < b?.email) {
            return -1;
        } else if (a?.email > b?.email){
            return 1;
        } else {
            return 0;
        }
    },
})
  
const profilesSlice = createSlice({
    name: 'profiles',
    initialState: profilesAdapter.getInitialState(),
    reducers: {
    },
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
            .addCase(logout.pending, (state) => {
                profilesAdapter.removeAll(state)
            })
            .addCase(postApi.fulfilled, (state, action) => {
                if(action?.payload?.profiles?.updated?.length >0){
                    profilesAdapter.upsertMany(state, action.payload.profiles.updated)
                }
                if(action?.payload?.profiles?.deleted?.length >0){
                    profilesAdapter.removeMany(state, action.payload.profiles.deleted)
                }
            })
          
    }
})
  
// selector must be given entire state; knows how to find slice
export const profilesSelectors = profilesAdapter.getSelectors((state) => state.profiles)


export default profilesSlice.reducer