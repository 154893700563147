import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { logout } from './thunks';
import { postApi } from './thunkPostApi';

const campaignsAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => i.SK.substring(2),
})
  
const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState: campaignsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
          .addCase(logout.pending, (state) => {
            campaignsAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.campaigns?.updated?.length >0){
                campaignsAdapter.upsertMany(state, action.payload.campaigns.updated)
            }
            if(action?.payload?.audiences?.deleted?.length >0){
              campaignsAdapter.removeMany(state, action.payload.campaigns.deleted)
            }
          })
    }
})

export default campaignsSlice.reducer