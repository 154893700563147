import { useDispatch } from "react-redux";
import { setConfig } from "../../state/configSlice";

export const NavLogo = () => {
  const dispatch = useDispatch();
  
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(setConfig({activeView:'stream'}));
  }
  
  return (
    <div tabIndex="0" className="NavLogo" onClick={(e)=>handleClick(e)}>
        <img src={'https://poditude.com/icon_192_192.png'}  alt="Poditude Logo" className="logoImg img-fluid" style={{color: '#0496ff'}}></img>
        <h1 className="logoText">Poditude</h1>
    </div> 
  );
}
