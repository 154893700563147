import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';
import { hasIdenticalParams } from './stateLib';
import { decryptResponse } from '../encrypt/decryptResponse';
import { encryptBody } from '../encrypt/encryptBody';

const isUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
  return regexExp.test(str)
} 

const enrichObj = ({path, obj=null, state}) => {
  if(path === '/refreshEntity'){
    const refreshEntities = state.refresh.entities || null;
    obj = (refreshEntities) ? Object.values(refreshEntities).filter((v) => v.nextRefresh < Date.now()) : null;
  } else if(path === '/getStream'){
    obj = {
      feedname: (obj.feedname) ? obj.feedname : state.config.activeFeedname, 
      actor : (isUUID(state.config.activeFeedactor)) ? state.config.activeFeedactor : "nonauth",
      next: state.config.streamNext
    }
  }
  return obj
}

//poditude api 
export const postApi = createAsyncThunk(
    'thunk/postApi',
    async ({path, obj}, {getState}) => {
      if(path === '/getProfile'){await Auth.currentSession()};
      obj = enrichObj({path, obj, state: getState()});
      if(obj === 'RETURN'){
        return 
      }
      obj = await encryptBody(obj);
      const response = await API.post('poditude', path, {body: obj})
      const result = await decryptResponse(response)
      return result
    },
    {
      //check condition before calling API
      condition: (arg, { getState }) => {
        const {obj, path} = arg;
        if (path === '/refreshEntity'){
          const state = getState();
          const authUser = state.config.authUser;
          const refreshEntities = state.refresh.entities || null;
          const overdue = (refreshEntities) ? Object.values(refreshEntities).filter((v) => v.nextRefresh < Date.now()) : null;
          if(!overdue || overdue?.length === 0 || authUser === 'nonauth'){
            return false
          } 
        } else if(path === '/getAudience'){
          const state = getState();
          const authUser = state.config.authUser;
          if(!obj.audienceId || obj?.audienceId === 'nonauth' || obj?.audienceId === authUser){
            return false
          }
        } else if (path === '/getChart') { 
          //for getChart check for existing refresh item and cancel API call if found
          const {id, period, dates='@@@', AB='@@@', required=null} = obj
          const { refresh } = getState()
          const signature =`chart#${id}#period_${period}#dates_${dates}#AB_${AB}`
          const altSignature =`chart#${id}#period_${period}#dates_${dates}#AB_both`
          if (!required && (refresh?.entities?.[signature] || refresh?.entities?.[altSignature])) {
            // No need to make an API call
            return false
          }
        } else {
          const shortPath = path.substring(1)
          const { debounce } = getState()
          if(debounce[shortPath] && hasIdenticalParams(debounce[shortPath], obj)){
            return false
          }
        }
        return true
      }
    }
)
