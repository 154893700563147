import { IconButton } from "../actionButtons/IconButton";
import { setConfig } from "../../state/configSlice";
import { setAudienceForm } from "../../state/audienceFormSlice";
import { clearSearch } from "../../state/searchSlice";
import { postApi } from "../../state/thunkPostApi";
import { useDispatch,useSelector } from 'react-redux';
import { useWindowSize } from "../../hooks/useWindowSize";
import { FaAddressCard, FaBroadcastTower, FaHistory, FaHome, FaInfoCircle, FaPodcast, FaMoneyBillWave, FaPen, FaRegComments, FaRegEnvelope, FaThList, FaTv, FaUserLock, FaQuestion} from "react-icons/fa";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { selectNotificationIdsByPK } from "../../state/notificationsSlice";
import { selectPermissionIdsByPK } from "../../state/permissionsSlice";

export const NavLeft = () => {  
  let navButtons =[]
  const dispatch = useDispatch();

  //global state
  const authUser = useSelector((state) => state.config.authUser);
  const displayAudienceAdmin = useSelector((state) => state.audienceForm.displayAudienceAdmin);
  const displayProfile = useSelector((state) => state.config.displayProfile);
  const displayNotifications = useSelector((state) => state.config.displayNotifications);
  const displayChannelSelector = useSelector((state) => state.config.displayChannelSelector);
  const activeView = useSelector((state) => state.config.activeView);
  const selectedAudience = useSelector((state) => state.audienceForm.selectedAudience);
  const notifications = useSelector((state)=> selectNotificationIdsByPK(state, state.config.authUser)) || [];
  const permissions = useSelector((state)=> selectPermissionIdsByPK(state, authUser)) || [];
  const windowSize = useWindowSize();

  const scrollToTop = () => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    },200)}

  //onClick functions for left nav buttons
  //buttons altering available view
  const clickHome = () => {
    scrollToTop();
    window.history.replaceState({}, '', '/');
    const actor = (authUser && authUser !== 'nonauth') ? authUser : 'nonauth';
    dispatch(setConfig({activeView: 'stream', activeFeedname: 'user' , activeFeedactor: actor, displayChannelSelector: false, selectedChannel: null, displaySkeleton:true}));
    dispatch(setAudienceForm({focusActivity:"", selectedAudience: "", selectedNetwork:""}));
    dispatch(postApi({path:'/getStream', obj:{feedname: 'user', actor: actor, next: null}}))
    if(authUser && authUser !== 'nonauth'){dispatch(postApi({path:'/getProfile', obj:{profileId: authUser, scope: 'n'}}))}
  }

  const clickChannel = () => {
    scrollToTop();
    window.history.replaceState({}, '', '/');
    dispatch(setConfig({activeView: 'stream', displayChannelSelector: !displayChannelSelector}))
  }

  const clickSearch = () => {
    scrollToTop();
    window.history.replaceState({}, '', '/');
    dispatch(clearSearch());
    dispatch(setConfig({activeView: 'streamSearch', displayChannelSelector: false, selectedChannel: null,}))
  }

  const clickTimeline = () => {
    window.scroll(0,0);
    window.history.replaceState({}, '', '/');
    dispatch(setConfig({activeView: 'timeline', activeFeedname: 'timeline' , activeFeedactor: authUser, displayChannelSelector:false, selectedChannel: null,}))
  }

  const clickAudience = () => {
    scrollToTop();
    window.history.replaceState({}, '', '/');
    if(authUser && authUser !== 'nonauth'){
      dispatch(setAudienceForm({ focusChart: 'membersDemo', focusActivity:"", selectedAudience:""}))
      dispatch(setConfig({activeView: 'audienceHome', activeFeedname: 'timeline', activeFeedactor: "", displaySkeleton: false}))
    }
  }

  //buttons impacting display option without changing view
  const clickProfile = () => {
    if(authUser && authUser !== 'nonauth'){
      scrollToTop();
      dispatch(setConfig({displayProfile: !displayProfile}))
    }
  }

  //reset audienceForm when changing activeView
  const resetNetwork = () => {
    window.history.replaceState({}, '', '/');
    dispatch(setConfig({activeView: 'audienceNetwork'}))
    dispatch(setAudienceForm({displayAudienceCreate: false, selectedAudience: null, selectedNetwork: null, activeNetwork: null, postType: 'network'})); 
  }

  //navs for all users
  navButtons.push({icon:<FaHome/>, onClick:clickHome, tooltip: 'Home', color: 'blue', css: "navButton", tipLocation:"right"})
 
  //navs for auth users only 
  if(authUser && authUser !== 'nonauth' && activeView==='stream'){navButtons.push({icon:<FaTv/>, onClick:clickChannel, tooltip: 'Choose a Channel', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(authUser && authUser !== 'nonauth' && !activeView?.startsWith('audience')){navButtons.push({icon:<FaPodcast/>, onClick:clickSearch, tooltip: 'Find Podcasts', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(authUser && authUser !== 'nonauth' && !activeView?.startsWith('audience')){navButtons.push({icon:<FaHistory/>, onClick:clickTimeline, tooltip: 'Your Timeline', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(authUser && authUser !== 'nonauth' && !activeView?.startsWith('audience')){navButtons.push({icon:<FaAddressCard/>, onClick:clickProfile, tooltip: 'Manage Profile', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(authUser && authUser !== 'nonauth' && windowSize > 975 ){navButtons.push({icon:<FaBroadcastTower/>, onClick:clickAudience, tooltip: 'Audience Home', color: 'blue', css: "navButton", tipLocation:"right"})}
  //navs if notifications present
  if((authUser && authUser !== 'nonauth' && notifications.length>0 && activeView === 'stream')|| displayNotifications){navButtons.push({icon:<FaRegEnvelope/>, onClick:() => {dispatch(setConfig({displayNotifications: !displayNotifications}))}, tooltip: 'Manage Messages', color: 'blue', css: "navButton", tipLocation:"right"})}
  
  //navs for auth users in audience view
  if(activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<PiShareNetworkDuotone/>, onClick:() => {resetNetwork()}, tooltip: 'Manage Networks', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(selectedAudience && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaThList/>, onClick:() => {dispatch(setConfig({activeView: 'audienceActivities', activeFeedname: 'timeline'}))}, tooltip: 'Review Posts', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(selectedAudience && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaPen/>, onClick:() => {dispatch(setConfig({activeView: 'audiencePost', activeFeedname: 'timeline'}))}, tooltip: 'New Post', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(selectedAudience && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaQuestion/>, onClick:() => {dispatch(setConfig({activeView: 'audienceSurvey', activeFeedname: 'survey'}))}, tooltip: 'Review Surveys', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(selectedAudience && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaRegComments/>, onClick:() => {dispatch(setConfig({activeView: 'audienceComments', activeFeedname: 'timeline'}))}, tooltip: 'Review Comments', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(selectedAudience && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaUserLock/>, onClick:() => {dispatch(setAudienceForm({displayAudienceAdmin: !displayAudienceAdmin}))}, tooltip: 'Manage Admins', color: 'blue', css: "navButton", tipLocation:"right"})}
  if(selectedAudience && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaInfoCircle/>, onClick:() => {dispatch(setConfig({activeView: 'audienceInfo'}))}, tooltip: 'Edit Audience Details', color: 'blue', css: "navButton", tipLocation:"right"})}
  
  //Poditude only for advertiser management
  if(selectedAudience==='Poditude' && activeView && activeView?.startsWith('audience') && permissions.length>0){navButtons.push({icon:<FaMoneyBillWave/>, onClick:() => {dispatch(setConfig({activeView: 'audienceAds'}))}, tooltip: 'Ad Campaigns', color: 'blue', css: "navButton", tipLocation:"right"})}

  return (
    <div className="NavLeft" role="navigation">
      {navButtons.map((item, idx) => <IconButton index={idx} icon={item.icon} onClick={item.onClick} color={item.color} css={item.css} tipLocation={item.tipLocation} tooltip={item.tooltip} key={'navButton' + idx}/> )}
    </div>
  );
} 