import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';
import { decryptResponse } from '../encrypt/decryptResponse';

const isUUID = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
  return regexExp.test(str)
} 

//COGNITO RELATED THUNKS
//get cognito auth user
export const getAuthenticatedUser = createAsyncThunk(
  'thunk/getAuthenticatedUser',
  async () => {
    window.scroll(0,0)
    let response
    await Auth.currentSession()
    .then((user) => {
      if(isUUID(user.accessToken?.payload?.username)){
        response = user.accessToken.payload.username
      } else {
        response = 'nonauth'
      }
    })
    .catch(() => {
      response =  'nonauth' 
    })
    return response
  },
  {
    //check condition before calling API
    condition: (_, { getState }) => {
      const { debounce } = getState()
      if(debounce.getAuthenticatedUser){
        return false
      } else {
        return true
      }
    }
  },
)

//login
export const login = createAsyncThunk(
  'thunk/login',
  async ({username, password}, {getState}) => {
    const state = getState()
    const activeView = state.config.activeView;
    const user = await Auth.signIn(username, password)
    if(user?.challengeName==='NEW_PASSWORD_REQUIRED'){
      return user.challengeName
    } else {
      return {user: user.username, activeView, displayLoginSignup: false}
    }
  }
)

//logout
export const logout = createAsyncThunk(
  'thunk/logout',
  async () => {
    await Auth.signOut()
    return 
  }
)

//signup
export const signup = createAsyncThunk(
  'thunk/signup',
  async ({username, password}) => {
    const response = await API.post('poditude', '/registerUser', {body: {username, password}})
    return decryptResponse(response)
  }
)

//confirm signUp
export const confirmSignUp = createAsyncThunk(
  'thunk/confirmSignUp',
  async ({username, code}) => {
    await Auth.confirmSignUp(username, code)
    return
  }
)

//resend confirmation code
export const resendConfirmationCode = createAsyncThunk(
  'thunk/resendConfirmationCode',
  async ({username}) => {
    await Auth.resendSignUp(username)
    return
  }
)

//send forgot password code
export const sendForgotPasswordCode = createAsyncThunk(
  'thunk/sendForgotPasswordCode',
  async ({username}) => {
    await Auth.forgotPassword(username)
    return
  }
)

//confirm forgot password code
export const confirmForgotPassword = createAsyncThunk(
  'thunk/confirmForgotPassword',
  async ({username, new_password, code}) => {
    await Auth.forgotPasswordSubmit(username, code, new_password)
    return
  }
)

//change email
export const changeEmail = createAsyncThunk(
  'thunk/changeEmail',
  async ({email}) => {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, {'email': email,})
    .then(async() => {
      await Auth.currentAuthenticatedUser({bypassCache: true});
      return
    })
    .then(r => {return r})
  }
)
 
//verify email
export const verifyEmail = createAsyncThunk(
  'thunk/verifyEmail',
  async ({code, profileId, email}) => {
    const result =  await Auth.verifyCurrentUserAttributeSubmit('email', code)
    if(result === 'SUCCESS'){
      const result2 =  await API.post('poditude', '/updateProfileEmail', {body: {profileId, email}});
      return decryptResponse(result2)
    }
  }
)

export const sendVerifyEmailCode = createAsyncThunk(
  'thunk/sendVerifyEmailCode',
  async ({attr}) => {
    await Auth.verifyCurrentUserAttribute(attr)
    .then(r => {return r})
  }
)

//confirm adminCreateUser
export const confirmAdminCreateUser = createAsyncThunk(
  'thunk/confirmAdminCreateUser',
  async({username, password, new_password}) => {
    try{
      const user = await Auth.signIn(username, password);
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        try{
          const user2 = await Auth.completeNewPassword(user, new_password)
          return user2.username
        } catch(e){
            return {msg: 'Credentials expired.'}
        }
      } else {
        return {msg: 'Please retry later'};
      }
    }catch(e){
      try{
        await Auth.resendSignUp(username)
        return {msg:'Credentials expired.  Please check your email for a new link.'}
      }catch(e){
        return {msg: 'Too many attempts. Please wait and try again.'}
      }
    }
  }
)

//logError
export const logError = createAsyncThunk(
  'thunk/logError',
  async ({obj}) => {
    const response = await API.post('poditude', '/logError', {obj})
    return decryptResponse(response)
  }
)

