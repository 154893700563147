import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { logout, verifyEmail } from './thunks';
import { postApi } from './thunkPostApi';
  
const userAdapter = createEntityAdapter({
    selectId: (i) => i.PK,
    
})
  
const userSlice = createSlice({
    name: 'user',
    initialState: userAdapter.getInitialState(),
    reducers: {
      deleteSubscription: (state, action) => {
        const { payload } = action;
        //expects payload as obj with {audienceId, profileId}
        const idx = state.entities[payload.profileId].subscriptions.filter(x => x?.[payload.audienceId])
        state.entities[payload.profileId].subscriptions.splice(idx,1)
      },
    },
    extraReducers: (builder) => {
      //state in extraReducers is just this slice
        builder
          .addCase(logout.pending, (state) => {
            userAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.user?.updated?.length >0){
                userAdapter.setAll(state, action.payload.user.updated)
            }
            if(action?.payload?.user?.deleted?.length >0){
                userAdapter.removeMany(state, action.payload.user.deleted)
            }
          })
          .addCase(verifyEmail.fulfilled, (state, action) => {
            userAdapter.upsertOne(state, action.payload)
          })
    }
})

//user selector
export const selectUser = createSelector(
    [(state) => state.user?.entities, (state) => state.config?.authUser],
    (user, authUser) => {
      if(authUser !== 'nonauth') {
        const merged = {...user[authUser], ...{authUser}};
        return merged;
      } else {
        return {authUser};
      }   
    }
)

//get subscriptionIds
export const selectSubscriptionIds = createSelector(
  [(state) => state.user?.entities, (state) => state.config?.authUser],
  (users, authUser) => {
      if(authUser !== 'nonauth' && users?.[authUser]?.subscriptions) {
        return users[authUser].subscriptions.reduce((acc, item) => {
          acc.push(Object.keys(item)[0])
          return acc
        }, [])
      } else {
        return []
      }
  }
)


//get merged subscription and audience
export const selectSubscribedAudiences = createSelector(
  [(state) => state.user?.entities, (state) => state?.audiences?.entities, (state) => state.config?.authUser],
  (users, audiences, authUser) => {
    if(authUser !== 'nonauth' && users?.[authUser]?.subscriptions) {
      return users[authUser].subscriptions.reduce((acc, item) => {
        const [id, date] = Object.entries(item)[0];
        if(audiences?.[id]){
          acc.push({
            ...audiences[id],
            memberSince: date,
            audienceId: id
          })
        }
        return acc
      }, [])
    } else {
      return []
    }
  }
)

export const { deleteSubscription } = userSlice.actions
export default userSlice.reducer