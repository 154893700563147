import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi'; 
const actionsAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => `${i.PK}#${i.SK.substring(2)}`,
})
  
const actionsSlice = createSlice({
    name: 'actions',
    initialState: actionsAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
      //state in extraReducers is just this slice
        builder
          .addCase('activities/deleteActivity', (state, action) => {
            if(state?.entities){
              const ids = Object.values(state.entities).filter(v => v.activityId === action.payload).map(v => `${v.PK}#${v.SK.substring(2)}`)
              actionsAdapter.removeMany(state, ids)
            }
          })
          .addCase('config/resetStream', (state) => {
            actionsAdapter.removeAll(state)
          })
          .addCase(login.fulfilled, (state, action) => {
            if(action?.payload !== 'NEW_PASSWORD_REQUIRED' && action?.payload?.activeView !== 'activity' && action?.payload?.activeView !== 'podcast'){
                actionsAdapter.removeAll(state)
            }
          })
          .addCase(logout.pending, (state) => {
            actionsAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.actions?.updated?.length >0){
                actionsAdapter.upsertMany(state, action.payload.actions.updated)
            }
            if(action?.payload?.actions?.deleted?.length >0){
                actionsAdapter.removeMany(state, action.payload.actions.deleted)
            }
          })
    }
})

export default actionsSlice.reducer