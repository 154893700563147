export const DEMOGRAPHIC_OPTIONS = () => {
    return( 
        {
            Age: {
                AA: '<18',
                AB: '18-29',
                AC: '30-39',
                AD: '40-49',
                AE: '50-59',
                AF: '60-69',
                AG: '70+',
                AU: 'Undeclared'
            },
            Cohort: {
                CA: 'Greatest',
                CB: 'Silent',
                CC: 'Boomer',
                CD: 'Gen X',
                CE: 'Millenial',
                CF: 'Gen Z',
                CU: 'Undeclared'
            },
            Education: {
                EA: 'Some HS',
                EB: 'HS Grad',
                EC: 'Trade Sch',
                ED: 'Bach Deg',
                EE: 'Grad Deg',
                EU: 'Undeclared'
            },
            Gender: {
                GA: 'Male',
                GB: 'Female',
                GC: 'Other',
                GU: 'Undeclared'
            },
            Income:{
                IA: '<25K',
                IB: '<50K',
                IC: '<100K',
                ID: '<200K',
                IE: '200K+',
                IU: 'Undeclared'
            },
            Politics:{
                PA: 'Very Lib',
                PB: 'Liberal',
                PC: 'Independent',
                PD: 'Conservative',
                PE: 'Very Con',
                PU: 'Undeclared'
            }
        }
    )

}

export const DEMOGRAPHIC_ACTORS  = {
    TXX: 'Total Respondents',
    UXX: 'Unique Respondents',
    TAA: 'Total <18 Year Old Respondents',
    TAB: 'Total 18-29 Year Old Respondents',
    TAC: 'Total 30-39 Year Old Respondents',
    TAD: 'Total 40-49 Year Old Respondents',
    TAE: 'Total 50-59 Year Old Respondents',
    TAF: 'Total 60-69 Year Old Respondents',
    TAG: 'Total 70+ Year Old Respondents',
    TAU: 'Total Undeclared Age Respondents',
    UAA: 'Unique <18 Year Old Respondents',
    UAB: 'Unique 18-29 Year Old Respondents',
    UAC: 'Unique 30-39 Year Old Respondents',
    UAD: 'Unique 40-49 Year Old Respondents',
    UAE: 'Unique 50-59 Year Old Respondents',
    UAF: 'Unique 60-69 Year Old Respondents',
    UAG: 'Unique 70+ Year Old Respondents',
    UAU: 'Unique Undeclared Age Respondents',
    TCA: 'Total Greatest Generation Respondents',
    TCB: 'Total Silent Generation Respondents',
    TCC: 'Total Boomer Generation Respondents',
    TCD: 'Total Gen X Respondents',
    TCE: 'Total Millenial Generation Respondents',
    TCF: 'Total Gen Z Respondents',
    TCU: 'Total Undeclared Generation Respondents',
    UCA: 'Unique Greatest Generation Respondents',
    UCB: 'Unique Silent Generation Respondents',
    UCC: 'Unique Boomer Generation Respondents',
    UCD: 'Unique Gen X Respondents',
    UCE: 'Unique Millenial Generation Respondents',
    UCF: 'Unique Gen Z Respondents',
    UCU: 'Unique Undeclared Generation Respondents',
    TEA: 'Total Respondents with Some High School Education',
    TEB: 'Total Respondents with a High School Education',
    TEC: 'Total Respondents with a Trade School Education',
    TED: "Total Respondents with a Bachelor's Degree",
    TEE: 'Total Respondents with a Graduate Degree',
    TEU: 'Total Respondents with Undeclared Education',
    UEA: 'Unique Respondents with Some High School Education',
    UEB: 'Unique Respondents with a High School Education',
    UEC: 'Unique Respondents with a Trade School Education',
    UED: "Unique Respondents with a Bachelor's Degree",
    UEE: 'Unique Respondents with a Graduate Degree',
    UEU: 'Unique Respondents with Undeclared Education',
    TGA: 'Total Male Respondents',
    TGB: 'Total Female Respondents',
    TGC: 'Total Non-Binary Respondents',
    TGU: 'Total Undeclared Gender Respondents',
    UGA: 'Unique Male Respondents',
    UGB: 'Unique Female Respondents',
    UGC: 'Unique Non-Binary Respondents',
    UGU: 'Unique Undeclared Gender Respondents',
    TIA: 'Total Respondents with Income <25K',
    TIB: 'Total Respondents with Income <50K',
    TIC: 'Total Respondents with Income <100K',
    TID: 'Total Respondents with Income <200K',
    TIE: 'Total Respondents with Income >200K',
    TIU: 'Total Respondents with Undeclared Income',
    UIA: 'Unique Respondents with Income <25K',
    UIB: 'Unique Respondents with Income <50K',
    UIC: 'Unique Respondents with Income <100K',
    UID: 'Unique Respondents with Income <200K',
    UIE: 'Unique Respondents with Income >200K',
    UIU: 'Unique Respondents with Undeclared Income',
    TPA: 'Total Very Liberal Respondents',
    TPB: 'Total Liberal Respondents',
    TPC: 'Total Independent Respondents',
    TPD: 'Total Conservative Respondents',
    TPE: 'Total Very Conservative Respondents',
    TPU: 'Total Undeclared Politics Respondents',
    UPA: 'Unique Very Liberal Respondents',
    UPB: 'Unique Liberal Respondents',
    UPC: 'Unique Independent Respondents',
    UPD: 'Unique Conservative Respondents',
    UPE: 'Unique Very Conservative Respondents',
    UPU: 'Unique Undeclared Politics Respondents',
}

export const hasIdenticalParams = (stateArray, newObj) => {
    return stateArray.some((i) => {
      return  Object.keys(i).every((k) => i[k] === newObj[k])
    })
}