import { createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi';
  
const streamAdapter = createEntityAdapter({
    // id=foreign_id#time
    selectId: (i) => `${i.foreign_id}#${i.time}`,
    // sort alpha by creation date
    sortComparer: (a, b) => b.time.localeCompare(a.time),
})
  
const streamSlice = createSlice({
    name: 'comments',
    initialState: streamAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
        //state in extraReducers is just this slice
        .addCase('activities/deleteActivity', (state, action) => {
          streamAdapter.removeOne(state, action.payload)
        })
        .addCase('config/resetStream', (state) => {
          streamAdapter.removeAll(state) 
        })
        .addCase('config/resetAudience', (state) => {
          streamAdapter.removeAll(state) 
        })
        .addCase(login.fulfilled, (state, action) => {
          if(action?.payload !== 'NEW_PASSWORD_REQUIRED' && action?.payload?.activeView !== 'activity' && action?.payload?.activeView !== 'podcast'){
              streamAdapter.removeAll(state)
          }
        })
        .addCase(logout.pending, (state) => {
          streamAdapter.removeAll(state)
        })
        .addCase(postApi.fulfilled, (state, action) => {
          if(action?.payload?.stream?.updated?.length >0){
              streamAdapter.upsertMany(state, action.payload.stream.updated)
          }
          if(action?.payload?.stream?.deleted?.length >0){
              streamAdapter.removeMany(state, action.payload.stream.deleted)
          }
        })
    }
})

//functions for selectors
const getStreamValues = (state) => state.stream.entities;

// selector must be given entire state; knows how to find slice
export const selectStreamSortedByTime = createSelector(
  [getStreamValues],
  (stream) => {
      return Object.values(stream).sort((a,b) => (a.time > b.time) ? -1 : (b.time > a.time) ? 1 : 0)
  }
)
  

export default streamSlice.reducer