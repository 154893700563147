import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import { postApi } from './thunkPostApi'; 

const audiencesAdapter = createEntityAdapter({
    // id=PK
    selectId: (i) => i.PK,
    // sort alpha by title
    sortComparer: (a, b) => a.title.localeCompare(b.title),
})
  
const audiencesSlice = createSlice({
    name: 'audiences',
    initialState: audiencesAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        //state in extraReducers is just this slice
        builder
          .addCase('config/resetAudience', (state) => {
            audiencesAdapter.removeAll(state)
          })
          .addCase(login.fulfilled, (state, action) => {
            if(action?.payload !== 'NEW_PASSWORD_REQUIRED' && action?.payload?.activeView !== 'activity' && action?.payload?.activeView !== 'podcast'){
                audiencesAdapter.removeAll(state)
            }
          })
          .addCase(logout.pending, (state) => {
            audiencesAdapter.removeAll(state)
          })
          .addCase(postApi.fulfilled, (state, action) => {
            if(action?.payload?.audiences?.updated?.length >0){
                audiencesAdapter.upsertMany(state, action.payload.audiences.updated)
            }
            if(action?.payload?.audiences?.deleted?.length >0){
                audiencesAdapter.removeMany(state, action.payload.audiences.deleted)
            }
          })

    }
})

export default audiencesSlice.reducer