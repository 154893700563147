import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { logout } from "./thunks";
import { postApi } from './thunkPostApi';

const refreshAdapter = createEntityAdapter({
  // id=PK#SK
  selectId: (i) => i.signature,
}) 

export const refreshSlice = createSlice({
  name: 'refresh',
  initialState: refreshAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
    //state in extraReducers is just this slice
      .addCase('activities/deleteActivity', (state, action) => {
        const ids = Object.keys(state.entities).filter((k => k.startsWith(`activity#${action.payload}`)))
        refreshAdapter.removeMany(state, ids)
      })
      .addCase('config/resetStream', (state) => {
        const ids = state.ids.filter(x => x.startsWith('activity'))
        refreshAdapter.removeMany(state, ids)
      })
      .addCase('config/resetAudience', (state) => {
        const ids = state.ids.filter(x => x.startsWith('activity') || x.startsWith('audience') || x.startsWith('chart'))
        refreshAdapter.removeMany(state, ids)
      })
      .addCase(logout.pending, (state) => {
        refreshAdapter.removeAll(state) 
      })
      .addCase(postApi.pending, (state, action) => {
        const { meta } = action;
        if (meta?.arg?.path === '/getChart'){
          const obj = {...action?.meta?.arg?.obj}
          if(obj?.required) {delete obj.required}
          //check for signatures in refresh
          const {id, period, dates='@@@', AB='@@@'} = obj;
          const signature = `chart#${id}#period_${period}#dates_${dates}#AB_${AB}`;
          const altSignature =`chart#${id}#period_${period}#dates_${dates}#AB_both`
          if(state?.ids?.includes(signature) || state?.ids?.includes(altSignature)){
            return state
          } else {
            refreshAdapter.upsertOne(state, {
              signature,  
              status: 'pending',
              nextRefresh: 'TBD',
              params: {...obj}
            })
          }
        } else if (meta?.arg?.path === '/refreshEntity'){
          const {obj} = action.meta.arg;
          const newState = obj.reduce((acc, item) => {
            let v2 = {...item, ...{status: 'refreshing'}}
            acc['entities'][item.signature] = v2;
            return acc
          }, state)
          return newState
        }
      })
      .addCase(postApi.fulfilled, (state, action) => {
        if(action?.payload?.refresh?.updated?.length >0){
          refreshAdapter.upsertMany(state, action.payload.refresh.updated)
        }
        if(action?.payload?.refresh?.deleted?.length >0){
          refreshAdapter.removeMany(state, action.payload.refresh.deleted)
        }
      })
  }, 
})

export default refreshSlice.reducer
