import { Amplify } from 'aws-amplify';

export const amplifyConfig = () => {
  Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_REGION, // (required) - Region where Amazon Cognito project was created
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID, // (optional) - Amazon Cognito Identity Pool ID
    aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
    Auth: {
      mandatorySignIn: false, //EDIT THIS
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    Storage: {
      AWSS3: {
        region: process.env.REACT_APP_REGION,
        bucket: process.env.REACT_APP_BUCKET,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
      }
    },
    aws_cloud_logic_custom: [
      {
        name: 'poditude', // (required) - API Name (This name is used used in the client app to identify the API - API.get('your-api-name', '/path'))
        endpoint: (process.env.REACT_APP_STAGE === 'prod') ? 'https://api.poditude.com' : process.env.REACT_APP_API_URL, // (required) -API Gateway URL + environment
        region: process.env.REACT_APP_REGION // (required) - API Gateway region
      }
    ],
    API: {
      endpoints: [
        {
          name: "poditude",
          endpoint: (process.env.REACT_APP_STAGE === 'prod') ? 'https://api.poditude.com' : process.env.REACT_APP_API_URL,
          region: process.env.REACT_APP_REGION,
        },
      ]
    }
  }); 
}
